<template>  

  <middleware-sidebar-outline
    :uuid="uuid"
    :middlewareID="middlewareID"
    :middlewareType="middlewareType"
    @close="$emit('close')"
    @shown="$emit('shown')"
    @saveMiddleware="buildAgents"
    v-model="variables"
  >
    <div v-if="middlewareData.message && variables" class="mt-1">
      <section>
        
        <div class="mb-2">
          <label>Cryptography type</label>
          <b-form-select :options="cryptography_types" v-model="middlewareData.type.register_1.value" text-field="label" value-field="type"> 
            
          </b-form-select>
        </div>
        
        <custom-input
          :id="`custom-input-sheet-id`"
          :possibleValues="variables"
          v-model="middlewareData.public_key.register_1"
        >
          <template #label>
            <span class="">
              Public key (optional)
            </span>
            <span class="float-right text-right mx-25 text-secondary">
              optional field for Public Key (must have 32 characters)
            </span>
          </template>
        </custom-input>

        <custom-input
          v-if="middlewareData.message.register_1 != undefined && variables"
          textArea
          :id="`custom-input-sheet-id`"
          :possibleValues="variables"
          v-model="middlewareData.message.register_1"
        >
          <template #label>
            <span class="text-capitalize">
              Message
            </span>
          </template>
        </custom-input>

      </section>
      
      <div class="text-secondary mt-1 small">
        <b-icon icon="circle-fill" scale="0.4" variant="secondary"/> Resulted encryption is stored in $.RESPONSE.ENCRYPT_MSG
      </div>

    </div>
  </middleware-sidebar-outline>
  
  </template>
  
  <script>
  import { 
    BPopover,
    BIcon,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BContainer,
    BSidebar,
    BSpinner,
    BTabs,
    BTab,
    BCardText,
    BCardBody,
    BCard,
    BAvatar,
    BLink,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BSkeleton,
    BCollapse,
  } from 'bootstrap-vue'
  import { v4 as uuidv4 } from 'uuid'
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import VariablesPanel from "../VariablesPanel.vue";
  import { makeToast } from '@/layouts/components/Popups'
  import Source from '@/custom/class/Agent/Source'
  import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
  import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
  import HelperTooltip from '@/layouts/components/HelperTooltip'
  import GoogleSheetsOperation from '@/custom/class/Enum/GoogleSheetsOperation.js'
  import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
  import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
  import Middlewares from "@/custom/class/Enum/Middlewares.js"
  import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
  import EncryptionTypes from "@/custom/class/Enum/EncryptionTypes.js"

    export default {
      mixins: [MiddlewaresMixin],
      components: {
        BPopover,
        BSidebar,
        BIcon,
        BButton,
        BRow,
        BCol,
        BInputGroup,
        BInputGroupAppend,
        BFormInput,
        BContainer,
        VuePerfectScrollbar,
        VariablesPanel,
        BSpinner,
        BTabs,
        BTab,
        BCardText,
        BCard,
        BAvatar,
        BCardBody,
        BLink,
        CustomInput,
        HelperTooltip,
        BFormSelect,
        BFormGroup,
        BFormSelectOption,
        BSkeleton,
        BCollapse,
        MiddlewareSidebarOutline,
      },
      props: {
        i18nKey: {
          type: String,
          default: "encrypt"
        },
        middlewareID: {
          type: Number,
          required: true,
        },
        uuid: {
          type: String,
          required: true,
        },
        value: {
          type: Array,
          required: true,
        }
      },
      data() {
        return {
          uuidMap: {},
          variables: undefined,
          variant_map: undefined,
          script: '',
          cryptography_types: new EncryptionTypes().items,
          middlewareData: {
            type: null,
            public_key: null,
            message: null,
          },
          agentList: [],
          isSaving: false,
          middlewareType: new Middlewares().items.find(el=> el.id == 55),
  
          nameField: {
            type: 'CRYPTO_TYPE',
            public_key: 'PUBLIC_KEY',
            message: 'MESSAGE',
          },

          defaultAgentActionID: 3,
          defaultAgentBlockID: 1,
        }
      },
      computed: {
        agents: {
          get() {
            return this.value
          },
          set(value) {
            this.$emit('input', value)
          }
        },
        googleSheetsOp() {
          return new GoogleSheetsOperation().items
        },
      },
      mounted() {
        this.init();
      },
      methods: {
        init() {
          this.initializeAgents()
        },
        configureVars() {
          this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
          this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
        },
        isObject(item) {
          return item instanceof Object && !(item instanceof Array);
        },
        isArray(item) {
          return item instanceof Array;
        },
        buildAgents() {
          this.isSaving = true;
          this.agentList = [];
  
          Object.keys(this.middlewareData).forEach((key) => {
            // if (this.middlewareData[key].register_1.value && key != 'value') {
            this.agentList.push(DefaultAgent.defineToAPI(this.middlewareData[key], this.middlewareID));
            // }
          })
          this.saveAgents();
        },
        saveAgents() {
          this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
  
            this.$emit("saved", response.data);
            this.isSaving = false
          })
          .catch((error) => {
            this.isSaving = true
          });
        },
        initializeAgents() {
          Object.keys(this.middlewareData).forEach((key) => {
            this.middlewareData[key] = MiddlewareFunctions.getValueFrom(
              {
                default: DefaultAgent.setAgent(this, {
                  id: null,
                  enum_agent_action_id: this.defaultAgentActionID,
                  enum_agent_block_id: this.defaultAgentBlockID,
                  register_1: {
                    value: "",
                    source: "7",
                  },
                  register_destiny: {
                    source: "25",
                    value: this.nameField[key],
                  },
                  execution_order: 1,
                  fatal_on_fail: true,
                }),
                source: "25",
                value: this.nameField[key],
                type: this.defaultAgentActionID,
                from: {
                  source: "register_destiny",
                  value: "register_destiny"
                },
                modifier: false,
                multiple: false,
              },
              this.agents
            );
          })

          if (!this.middlewareData.type.register_1.value || this.middlewareData.type.register_1.value.length == 0){
            this.$nextTick(()=>{
              this.middlewareData.type.register_1.value = structuredClone(this.cryptography_types[0].type)
            })
          }
          
        },
        getID(key) {
          if (this.uuidMap[key]) {
            return this.uuidMap[key];
          }
  
          const uuid = uuidv4();
          this.uuidMap[key] = uuid;
  
          return uuid;
        },
        sidebarShown() {
          this.$emit("shown")
          this.isSaving = false;
  
          let el = document.getElementsByTagName("html");
          el[0].classList.add("hide-scrollbar");
        },
        sidebarHidden() {
          this.$emit("close");
  
          let el = document.getElementsByTagName("html");
          el[0].classList.remove("hide-scrollbar");
        },
        toggleSidebar() {
          this.$root.$emit("bv::toggle::collapse", this.uuid);
        },
        getSourceLabel(id) {
          const source = new Source(id);
          return this.$t(source.label);
        },
      }
  
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>